import Bar from "../Component/Bar";
import Footer from "../Component/Footer";
import { Icon } from '@iconify/react';
import React, { useState } from 'react'
import "../assets/css/contact.css"
import "../assets/css/home.css"


export default function Contact() {
    return (
        <div style={{backgroundColor:'#f9f9f9'}}>
          <Bar />
          
            
          <img className="image" src={require("../assets/images/nous_contacter_header.jpeg")} />
          
          
          <div style={{display:'flex',marginLeft:"2%", marginTop:100, marginBottom:100,justifyContent:"center",flexWrap:"wrap"}}>
            <div className="contact-card">
              <div>
                <div className="contact-title">Contact</div>
                <div className="contact-bar"></div>
              </div>
              <div>
                <div style={{display:'flex'}}>
                  <div className="contact-logo"><Icon style={{width:45, height:45, color:'#f36621'}} icon="ri:map-pin-user-fill" /></div>
                  <div>
                    <div className="contact-titre-logo">Adresse</div>
                    <div className="contact-titre-logo-2">203 Rue Michel Carré, 95870 Bezons</div>
                  </div>
                </div>

                <div style={{display:'flex'}}>
                  <div className="contact-logo"><Icon style={{width:45, height:45, color:'#f36621'}} icon="ri:phone-fill" /></div>
                  <div>
                    <div className="contact-titre-logo">Standard</div>
                    <div className="contact-titre-logo-2">+33 9 52 03 85 40</div>
                  </div>
                </div>

                <div style={{display:'flex'}}>
                  <div className="contact-logo"><Icon style={{width:45, height:45, color:'#f36621'}} icon="ic:outline-mail" /></div>
                  <div>
                    <div className="contact-titre-logo">E-Mail</div>
                    <div className="contact-titre-logo-2">admin@odafox.com</div>
                  </div>
                </div>


              </div>
            </div>
            <div>
              
              <iframe className="maps-style"
                loading="lazy"
                allowFullScreen
                referrerPolicy="no-referrer-when-downgrade"
                src={"https://www.google.com/maps/embed/v1/place?key="+process.env.REACT_APP_API_KEY+"&q=Pépinière d'entreprises de Bezons,203 Rue Michel Carré, 95870 Bezons+FR"+"&zoom=15"}>
              </iframe>
            </div>
            
          </div>


          {Footer()}
         
        </div>
    );
}