import Bar from "../Component/Bar";
import '../assets/css/home.css'
import '../assets/css/rpa.css'
import Footer from "../Component/Footer";
import { useEffect } from 'react';
export default function RPA() {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    const methodologyRPA = [
        {
            number:"01",
            text:"Compréhension du besoin"
        },
        {
            number:"02",
            text:"Analyse de l'éligibilité à l’Automatisation"
        },
        {
            number:"03",
            text:"Choix de la platforme"
        },
        {
            number:"04",
            text:"Conception du scénario et Réalisation"
        },
        {
            number:"05",
            text:"Test technique et fonctionnel"
        },
        {
            number:"06",
            text:"Industrialisation et Orchestration"
        },
    ]

    return (
        <div style={{backgroundColor:'#f9f9f9'}}>
            <Bar />
            <img className="image" src={require("../assets/images/rpa_header.jpeg")} />
            <div className='expertise-title'>
                <div className='subtitle-blue'>La main d’œuvre digitale</div>
                <div className='title'>L’Automatisation Intelligente des Processus (IPA, RPA) </div>
                <div className='container-bar'>
                <div className='middle-bar'></div>
            </div>
            <div className='subtitle-black'>L’Automatisation des Processus permet d’absorber plus de charge, de faire plus de choses sans ajouter de la main-d’œuvre.</div>
            
            <div className="rpa-advantage">
                <div className="image-RPA-container">
                    <img className="image-RPA" src={require("../assets/images/rpa_benefice.jpeg")}/>

                </div>
                
                <div>
                    
                    <div className='subtitle-RPA'>L’Automatisation Intelligente combine plusieurs technologies :</div>
                        <ul>
                            <li className="li-RPA">La RPA</li>
                            <li className="li-RPA">L’Intelligence Artificielle </li>
                            <li className="li-RPA">La Machine Learning </li>
                            
                        </ul>
                        <div className="text-RPA" style={{lineHeight:"200%"}}>Elle va au-delà de l’automatisation de tâches redondantes et fréquentes;<br/> Grâce à l’Intelligence Artificielle et à la Machine Learning, on étend les compétences du processus en lui octroyant la possibilité de scénariser son déroulement et de produire des modèles prédictifs.</div>

                    </div>
                </div>   
            </div>

            <div className='expertise-title'>
                <div className='subtitle-blue'>Exprimez votre besoin</div>
                <div className='title'>Nous sommes là pour y répondre</div>
                <div className='container-bar'>
                <div className='middle-bar'></div>
                </div>
            </div>


            <div className="card-RPA-list">
                <div className="card-RPA">
                    <div className="card-RPA-head">
                        <div className="card-RPA-circle"></div>
                        <div className="card-RPA-title">Une mise en place sereine avec l'appui d'experts</div>
                    </div>
                    <div className="card-RPA-bar"></div>
                    <div className="card-RPA-text">
                    ODAFOX peut vous conseiller pour créer votre centre d’Automatisation et vous accompagner pour sa mise en place. Bénéficiez de l’expertise de nos consultants en la matière et profitez pleinement des bénéfices des technologies d’Automatisation. 
                    </div>
                </div>

                <div className="card-RPA">
                    <div className="card-RPA-head">
                        <div className="card-RPA-circle"></div>
                        <div className="card-RPA-title">L'externalisation de votre centre RPA</div>
                    </div>
                    <div className="card-RPA-bar"></div>
                    <div className="card-RPA-text">
                    ODAFOX peut devenir votre centre d’Automatisation en mettant à votre disposition son équipe, son savoir-faire et ses plateformes de développement pour créer votre main-d’œuvre digitale.
                    Libérez vos collaborateurs des tâches à faible valeur ajoutée afin qu’ils puissent se concentrer sur vos clients ! 
                    </div>
                </div>
            </div>



            <div className="methodology-RPA">
                <div className='expertise-title'>
                    <div className='subtitle-blue'>L'automatisation... alliée de votre performance</div>
                    <div className='title'>La technologie RPA</div>
                    <div className='container-bar'>
                        <div className='middle-bar'></div>
                    </div>
                    <div className='subtitle-black'>Le cabinet ODAFOX a développé une expertise efficace dans la mise en place de la technologie de la RPA dans les entreprises. La démarche que nous vous proposons pour créer votre main d’œuvre digitale est la suivante : 
                    </div>

                </div>
                
                <div className="methodology-RPA-container">
                    {methodologyRPA.map((s)=><div key={s} style={{marginLeft:20}}>
                        <div className="number-RPA">{s.number}</div>
                        <div className="methodology-text-RPA">{s.text}</div>
                    </div>)}
                    

                </div>
                
            </div> 



           {Footer()}
        </div>
    );
}