import '../assets/css/bar.css'
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Icon } from '@iconify/react';
import logo from '../assets/images/ODAFOX.png'; // importe l'image

export default function Bar() {
    const [isShown, setIsShown] = useState(false);
    const [deviceSize, changeDeviceSize] = useState(window.innerWidth);
    const [isShownMobile1, setIsShownMobile1] = useState(false);
    const [isShownMobile2, setIsShownMobile2] = useState(false);

    console.log(deviceSize)

    useEffect(() => {
      const resizeW = () => changeDeviceSize(window.innerWidth);
  
      window.addEventListener("resize", resizeW); // Update the width on resize
  
      return () => window.removeEventListener("resize", resizeW);
    });
    
    if(deviceSize <= 991){
      return <div className='bar-mobile'>
        {isShownMobile1 ? <button className="element3-mobile" onClick={() => setIsShownMobile1(!isShownMobile1)}><Icon className='menu-logo' icon="akar-icons:cross" />
        </button>:<button className="element3-mobile" onClick={() => {setIsShownMobile1(!isShownMobile1);setIsShownMobile2(false)}}><Icon className='menu-logo' icon="bx:menu" />
        </button>}
          {isShownMobile1 ? <div className="-mobile">
              <Link to="/" className="element-mobile">ACCUEIL</Link>
              <button className="element-mobile" onClick={() => setIsShownMobile2(!isShownMobile2)}>NOS SERVICES
              {isShownMobile2 ? <div className="element2-mobile">
                <Link to="/Donnees" className='sub-element-mobile' style={{marginTop:15}}>Centre d'Analyse et d'Automatisation des Processus et des Données</Link>
                <Link to="/ProcessMining" className='sub-element-mobile'>Le Process Mining</Link>
                <Link to="/RPA" className='sub-element-mobile'>L’Automatisation Intelligente des Processus (IPA, RPA) </Link>
                {/*<Link to="/AnalyseDonnees" className='sub-element-mobile'>L'analyse de données et la détection de fraude</Link>*/}
              </div>
              :<></>
            }
              </button>
              <Link to="/Entreprise" className="element-mobile">NOTRE ENTREPRISE</Link>
              <Link to="/Contact" className="element-mobile" style={{paddingBottom:20}}>NOUS CONTACTER</Link>
              
            </div>
              :<></>
            }
            
      </div>
    }
    return (
        <div className="bar">
        <div className="left">
          <Link to="/">
            <img src={logo} alt="Logo" style={{ width: '185px', marginTop: "-25px" }} />
          </Link>
        </div>
        <div className="right">
          <Link to="/" className="element">ACCUEIL</Link>
            <button className="element3" onMouseEnter={() => setIsShown(true)} onMouseLeave={() => setIsShown(false)}>NOS SERVICES
            {isShown ? <div className="element2">
              <Link to="/Donnees" className='sub-element'>Centre d'Analyse et d'Automatisation des Processus et des Données</Link>
              <Link to="/ProcessMining" className='sub-element-new'>Le Process Mining</Link>
              <Link to="/RPA" className='sub-element-new'>L’Automatisation Intelligente des Processus (IPA, RPA) </Link>
              {/*<Link to="/AnalyseDonnees" className='sub-element-new'>L'analyse de données et la détection de fraude</Link>*/}
            </div> :<></>
            
            }
            
            </button>
            <Link to="/Entreprise" className="element">NOTRE ENTREPRISE</Link>
            <Link to="/Contact" className="element">NOUS CONTACTER</Link>
        </div>
        </div>
        
        
      
      
    );
  }

  