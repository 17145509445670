import Bar from "../Component/Bar";
import '../assets/css/home.css'
import '../assets/css/processmining.css'

import CardType4 from "../Component/CardType4";
import Carousel from "nuka-carousel";
import Footer from "../Component/Footer";
import { useEffect } from 'react';

export default function ProcessMining() {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    

    return (
        <div style={{backgroundColor:'#f9f9f9'}}>
            <Bar />
           <img className="image" src={require("../assets/images/process_mining_header.jpeg")} />
            <div className='expertise-title'>
                <div className='subtitle-blue'>ODAFOX</div>
                <div className='title'>Le Process Mining : l'exploitation des données</div>
                <div className='container-bar'>
                <div className='middle-bar'></div>
            </div>
            <div style={{display:"flex", justifyContent:"center"}}>
                    <div style={{maxWidth:"100%"}}>
                        <img className="process-flow" style={{maxWidth:"100%",borderRadius:10, boxShadow: "2px 2px 20px 0px rgb(50 50 50 / 21%)", marginBottom:15}}
                        src={require("../assets/images/process-mining-flow.jpg")}/>
                    </div>
            </div>

                
                
                <div className='subtitle-black'>Le Process Mining exploite les données générées par les systèmes informatiques pour analyser les processus de l’entreprise.</div>
                
                
            </div>
            <div className='grey-text'>
            La cartographie et les indicateurs ainsi élaborés, reflètent fidèlement l’état du processus.<br/>Deux analyses sont couramment effectuées : 
                    <ul>
                        <li>Analyse de performance</li>
                        <li>Analyse de conformité </li>
                    </ul>
            </div>

            <div className='expertise-title'>
                
                <div className='title'>L'Analyse de Performance</div>
                <div className='container-bar'>
                <div className='middle-bar'></div>
                </div>
                
                <div className='subtitle-black'>L’analyse de performance sert à identifier des leviers pour améliorer la performance du processus.</div>
                
                
            </div>

            <div className='container-card1'>
                <CardType4 number='01'
                title='Durée d’exécution'
                container="L’analyse du processus permettra d’identifier les goulots d’étranglement et proposera des solutions pour corriger durablement ces points faibles."
                img="performance.jpeg"/>

                <CardType4 number='02'
                    title='Ressources'
                    container="L’analyse des ressources permettra d’identifier la capacité d’absorption de la charge de travail, la surconsommation de ressources, les besoins en formation ..."
                    img="donnees.jpeg" />
                <CardType4 number='03'
                    title='Organisationnel'
                    container="L’analyse des entités et des fonctions qui interviennent dans le déroulement du processus simplifiera la redistributions de tâches et d’activités lors d’une réorganisation."
                    img="organisation.jpeg" />

            </div>

            <div className='expertise-title'>
                <div className='subtitle-blue'>Process Mining</div>
                <div className='title'>L'Analyse de Conformité </div>
                <div className='container-bar'>
                <div className='middle-bar'></div>
                </div>
                <div className="conformity-container">
                    <div>
                    <img className="conformity-image" src={require("../assets/images/conformite.jpeg")}/>
                    </div>
                    
                    <div style={{marginLeft:50}}>
                        <div className="conformity-title">L’analyse ou l’audit de conformité permet de : </div>
                        <div className="conformity-point"><div className="triangle"></div>Comparer le fonctionnement réel du processus avec le processus de référence et d’identifier toute déviation, </div>
                        <div className="conformity-point"><div className="triangle"></div>Mettre en évidence des non-conformités réglementaires, légales. </div>
                        <div className="conformity-point"><div className="triangle"></div>Détecter des malversations</div>
                    </div>
                
                </div>
            </div>


            

            <div className="subtitle-blue"  style={{marginTop: 120}}>Aucune dépense avant d’avoir démontré un retour sur investissement</div>
            <div className="title">Le Process dicovery</div>
            <div className='container-bar'>
                <div className='middle-bar'></div>
            </div>
            <div className="container-process-discovery">
                
                    <img className="image-process-discovery" src={require("../assets/images/process_discovery.jpeg")} />
                
                
                <div className="subtitle-black-process">
                <br />
                

                    La vision d'ODAFOX est d’instaurer une relation de partenariat avec chacun de ses clients. <br />
                    C’est pour cela qu’avant de vous lancer dans un projet, ODAFOX vous propose de démontrer que votre investissement apportera le gain escompté. <br />
                    Le Process Discovery ne constitue en rien une simple démo de faisabilité technique, il vous montre la représentation actuelle de votre processus 
                    telle qu’il est exécuté ainsi que ses principaux variants. <br />
                    Grâce à la découverte du processus, l’entreprise aura une compréhension complète de son processus et se rendra compte des déviations. <br />
                    L’analyse du processus à l’aide des données, identifiera ensuite les déviations et les améliorations en fonction des priorités métier. <br />
                    Le Process discovery livre un business case. 
                </div>
            </div>
            {/*
            <div className='expertise-title'>
                <div className='subtitle-blue'>Valeur Ajoutée ODAFOX</div>
                <div className='title'>Méthodologie Process Mining</div>
                <div className='container-bar'>
                    <div className='middle-bar'></div>
                </div>
            </div>
            
            <div className="image-PM">
                <img className="image-PM-content" src={require("../assets/images/methodo.png")} />
            </div>

            <div style={{marginBottom:100,display:"flex",justifyContent:"center"}}>

                <Carousel className="carousel-style">
                            {dataCarouselPM.map((s)=><div key={s} className="container-carousel">
                            <div className="carousel-nombre">{s.number}</div>
                            <div className="carousel-title">{s.title}</div>
                            <div className="carousel-subtitle-objectif">Objectif</div>
                            <div className="carousel-objectif">{s.objectif}</div>
                            <div className="carousel-subtitle-livrable">Livrable</div>
                            <div className="carousel-livrable">{s.livrable}</div>
                            <br />
                            <br />
                            </div>)}
                </Carousel>
            </div>
            
            */}


           {Footer()}

        </div>
    );
}