import { Icon } from '@iconify/react';

export default function CardType5(props) {
    const divStyle = {
        display:'flex',flexDirection:"row", width:"100%", maxWidth:props.maxWidth, marginLeft:15, marginRight: 15
      };
    return (
        <div style={divStyle}>
            <div>
                <Icon icon={props.img} color="#f36621" style={{ fontSize: '60px', marginTop:20, marginBottom:10 }}/>
            </div>
            <div style={{marginLeft: 15}}>
                <div style={{fontSize: 19, fontWeight: 500, fontFamily: "FiraSans1"}}>{props.title}</div>
                <p style={{fontSize: 15, color: "#6D6D6D", fontStyle:"italic" ,fontFamily: "FiraSans1", lineHeight:"150%"}}>{props.description}</p>
                {props.subDescription.length > 0 ? <ul>
                    {props.subDescription.map((element, i)=><li key={i} style={{fontSize: 15, color: "#6D6D6D", fontStyle:"italic" ,fontFamily: "FiraSans1", lineHeight:"150%"}}>{element}</li>)}
                </ul> : <></>}
            </div>
        </div>
    );
  }

  