import "../assets/css/cardtype1.css"
export default function CardType1(props) {
    return (
      <div className="card1">
          <img className="image-card1" src={require("../assets/images/"+props.img)}/>
          <div className="container-card-flex1">
            <div className="number-card1">{props.number}</div>
            <div className="title-card1">{props.title}</div>
          </div>
          
          <div className="text-card1">{props.container}</div>
      </div>
    );
  }

  