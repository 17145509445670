import {
  
  Routes,
  Route,
  HashRouter,
} from "react-router-dom";

import Home from './Screen/Home';
import CentreAuto from './Screen/CentreAuto'
import RPA from "./Screen/RPA";
import AnalyseDonnees from "./Screen/AnalyseDonnees";
import Entreprise from "./Screen/Entreprise";
import Contact from "./Screen/Contact";
import ProcessMining from "./Screen/ProcessMining";
import TaskMining from "./Screen/TaskMining";
import Mentions from "./Screen/Mentions";

function App() {
  return (
    <HashRouter>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/Donnees" element={<CentreAuto />} />
      <Route path="/ProcessMining" element={<ProcessMining />} />
      <Route path="/RPA" element={<RPA />} />
      {/*<Route path="/AnalyseDonnees" element={<AnalyseDonnees />} />*/}
      <Route path="/Entreprise" element={<Entreprise />} />
      <Route path="/Contact" element={<Contact />} />
      <Route path="/Mentions" element={<Mentions />} />
    </Routes>
  </HashRouter>
    
  );
}

export default App;
