import "../assets/css/footer.css"
import { Link } from 'react-router-dom';

export default function Footer() {
    return(
        <>
         <div className='footer'>
            <div className='container-footer'>
                <div className='contact-info'>
                    <div className='footer-title'>Contact</div>
                    <div className='footer-subtitle'>203 Rue Michel Carré, 95870 Bezons</div>
                    <div className='footer-subtitle'>Email: admin@odafox.com</div>
                    {<div className='footer-subtitle'>Standard: +33 9 52 03 85 40</div>}
                </div>

                <div className='compagny'>
                    <div className='footer-title'>Services</div>
                    <Link to="/ProcessMining" style={{textDecoration:"none"}}><div className='footer-subtitle'>Le Process Mining</div></Link>
                    <Link to="/RPA" style={{textDecoration:"none"}}><div className='footer-subtitle'>L’Automatisation Intelligente des Processus (IPA, RPA)</div></Link>
                    <Link to="/Mentions" style={{textDecoration:"none"}}><div className='footer-subtitle'>Mentions Légales</div></Link>
                </div>
            </div>
        </div>
        </>
    )
}



