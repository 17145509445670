import Bar from "../Component/Bar";
import "../assets/css/entreprise.css";
import Carousel from 'nuka-carousel';
import CardType2
 from "../Component/CardType2";
import Footer from "../Component/Footer";
import CardType5 from "../Component/CardType5";
export default function Entreprise() {

    const dataCarouselPM = [
        {
            number:"01",
            title:"Atelier de compréhension et de formalisation des besoins",
            objectif:"Comprendre et Déterminer les besoins et les objectifs du projet, Identifier les contraintes",
            livrable:"Proposition de services"
        },
        {
            number:"02",
            title:"Atelier d'organisation et de planification",
            objectif:"Identification des acteurs, des systèmes informatiques et des données du projet, identification des interlocuteurs",
            livrable:"Organisation et planification du projet, Description de l’environnement de Travail, Autorisation d’accès"
        },
        {
            number:"03",
            title:"Extraction/réception des données pertinentes",
            objectif:"Constitution de l’environnement de travail, Identification exhaustive des données, Préparation, Extraction/Réception des données (les données peuvent être anonymisées ou l'analyse peut aussi se faire sur un échantillon)",
            livrable:"Analyse des données"
        },
        {
            number:"04",
            title:"Création de la table d’activité",
            objectif:"Comprendre et Déterminer les besoins et les objectifs du projet, Identifier les contraintes",
            livrable:"Proposition de services"
        },
        {
            number:"05",
            title:"Création du modèle intégré",
            objectif:"Analyse du processus",
            livrable:"Rapport préliminaire"
        },
        {
            number:"06",
            title:"Présentation de l'étude",
            objectif:"Vérifier l’atteinte des objectifs du projet",
            livrable:"Rapport final et préconisations"
        },
    ]


    const citation=[
        {
            titre:"L'engagement de résultats",
            autheur:"Abraham Lincoln",
            description:"\"L'engagement est ce qui transforme une promesse en réalité\""
        },
        {
            titre:"Satisfaction client",
            autheur:"Guccio Gucci",
            description:"\"On se souvient de la qualité bien plus longtemps que du prix\""
        },
        {
            titre:"Quête d'excellence",
            autheur:"Thomas Boswell",
            description:"\"Il n'y a pas de substitut à l'excellence. Pas même le succès \""
        },
        {
            titre:"Transparence",
            autheur:"Lhaj-kacemi",
            description:"\"La transparence exige une responsabilité et une confiance partagées impliquant les partenaires pour une optimisation des prestations de service \""
        }
    ]
    
    return (
        <div style={{backgroundColor:'#f9f9f9'}}>
            <Bar />
            <img className="image" src={require("../assets/images/entreprise_header.jpeg")} />
            
            <div className="subtitle-blue">Tournés vers l'avenir</div>
            <div className="title">ODAFOX</div>
            <div className='container-bar'>
                <div className='middle-bar'></div>
            </div>
            <div className="subtitle-black">aide ses clients à exploiter leurs données numériques afin de gagner en performance et en efficience.</div>

            <div style={{display:'flex',flexDirection:"column",marginTop: "35px"}}>

                <div style={{display:'flex',flexDirection:"row",justifyContent:'center' , flexWrap: "wrap"}}>
                    <CardType5 maxWidth="550px" img="ic:twotone-tips-and-updates" title="Le conseil" description="Nous vous aidons à clarifier votre vision, définir votre stratégie opérationnelle et élaborer votre business case." subDescription={[]}/>
                    <CardType5 maxWidth="550px" img="material-symbols:group-add" title="L'accompagnement" description="Nous vous aidons à découvrir, cartographier et analyser vos processus opérationnels afin d'identifier vos opportunités d’amélioration grâce au Process Mining." subDescription={[]}/>
                </div>

                <div style={{display:'flex',flexDirection:"row",justifyContent:'center' , flexWrap: "wrap"}}>
                    
                    <CardType5 maxWidth="750px" img="ph:gear-duotone" title="L'optimisation" description="Nous vous aidons à réduire et maîtriser vos coûts:" subDescription={["coût des opérations : grâce à l'automatisation intelligente de vos processus (IPA/RPA)", "coût du patrimoine applicatif : grâce à l'approche FinOps et au multicloud."]}/>
                </div>
            </div>

        
            
            
            {/*
            <div className="subtitle-black" style={{marginTop:50}}>Nous nous appuyons sur 3 technologies pour fournir nos services :</div>
            
            <div className='container-card2'>
                <CardType2 title="Le Process Mining"
                container="Le Process Mining vous permettra de contrôler et d’améliorer les processus des systèmes d'information de votre entreprise."
                img="codicon:server-process"/>

                <CardType2 title="Build"
                container="Conception, développement et intégration d’applications. L'exécution de vos applications dans le cloud va vous permettre de gagner en flexibilité et de réadapter vos processus rapidement."
                img="fluent:task-list-add-24-regular"/>

                <CardType2 title="La RPA"
                container="L'Automatisation des processus métier de votre entreprise. Elle permet de répliquer des actions informatiques humaines redondante."
                img="bi:robot"/>


            </div>
            */}

            <div className="title" style={{marginTop: "35px"}}>Nos Valeurs</div>
            <div className='container-bar'>
                <div className='middle-bar'></div>
            </div>

            <div className="carousel-container">

                    <Carousel className="carousel-style"
                    defaultControlsConfig={{
                        nextButtonText: ">", prevButtonText: "<"}}
                    >
                        {citation.map((s)=><div key={s} className="carousel">
                        <div className="carousel-description">{s.description}</div>
                        <div className="carousel-autheur">{s.autheur}</div>
                        <div className="carousel-titre">{s.titre}</div>
                        </div>)}
                    </Carousel>
            </div>

            <div className='expertise-title'>
                <div className='subtitle-blue'>Valeur Ajoutée ODAFOX</div>
                <div className='title'>Méthodologie Process Mining</div>
                <div className='container-bar'>
                    <div className='middle-bar'></div>
                </div>
            </div>
            
            <div className="image-PM">
                <img className="image-PM-content" src={require("../assets/images/methodo.png")} />
            </div>

            <div style={{marginBottom:100,display:"flex",justifyContent:"center"}}>

                <Carousel className="carousel-style">
                            {dataCarouselPM.map((s)=><div key={s} className="container-carousel">
                            <div className="carousel-nombre">{s.number}</div>
                            <div className="carousel-title">{s.title}</div>
                            <div className="carousel-subtitle-objectif">Objectif</div>
                            <div className="carousel-objectif">{s.objectif}</div>
                            <div className="carousel-subtitle-livrable">Livrable</div>
                            <div className="carousel-livrable">{s.livrable}</div>
                            <br />
                            <br />
                            </div>)}
                </Carousel>
            </div>
           



            {Footer()}
        </div>
    );
}