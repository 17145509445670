import Bar from "../Component/Bar";
import Footer from "../Component/Footer";
import "../assets/css/home.css";
import CardType3 from "../Component/CardType3";
import { Icon } from '@iconify/react';
import "../assets/css/centreauto.css"
import { useEffect } from 'react';
import { Fragment } from "react";

export default function CentreAuto() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div style={{backgroundColor:'#f9f9f9'}}>
            <Bar />
            <img  className="image" src={require("../assets/images/centreauto_header.jpeg")} />
            <div className="subtitle-blue">La performance de vos processus détermine la réussite de votre organisation</div>
            <div className="title">ODAFOX - Centre d’analyse et d’automatisation des processus</div>
            <div className='container-bar'>
                <div className='middle-bar'></div>
            </div>
            <div className="subtitle-black">
            L’efficacité d’un processus est déterminée par : 
            </div>

            <div className="donnees-card">
                <div className="container-donnees-card">
                    <Icon style={{color:'#f36621', width:310, height:200}} icon="bi:graph-up-arrow" />
                    <div className="titre-centre">L'atteinte de son objectif</div>
                </div>

                <div className="container-donnees-card">
                    <Icon icon="dashicons:money-alt" style={{color:'#f36621', width:310, height:200}} />
                    <div className="titre-centre">La fluidité de ses échanges</div>
                </div>

                <div className="container-donnees-card">
                    <Icon style={{color:'#f36621', width:310, height:200}} icon="ant-design:cloud-sync-outlined" />
                    <div className="titre-centre">Son temps d’exécution et le coût des ressources qu’il utilise</div>
                </div>

            </div>

            <div className="subtitle-black" style={{marginTop:-50}}>{
                /*
                La transformation de toute organisation nécessite donc de revoir ses processus.
                Les résultats d’un Process Mining sont une aide précieuse à la décision.
                <br/>
                Avec le Process Mining, l’Automatisation des Processus est un des accélérateurs de la transformation numérique. 
                */
            }
                Toute transformation nécessite de revoir les processus, quel que soit l’organisation.  

            </div>


            <div className="subtitle-blue">Le savoir-faire ODAFOX</div>
            <div className="title">Centre d’Analyse et d’Automatisation de vos Processus</div>
            <div className='container-bar'>
                <div className='middle-bar'></div>
            </div>
            <div className="subtitle-black">Pour maîtriser le monde digital</div>

            <div className='container-card1'>
                <CardType3
                title='Le Process Mining'
                relativeUrl="ProcessMining"
                container={<Fragment>Le Process Mining est une technique qui permet de cartographier et d’analyser un processus grâce aux données qui sont générées par les outils informatiques utilisés lors du déroulement du Processus.<br/>
                <br/>Les résultats d’un Process Mining permettent d’identifier les opportunités d’optimisation du Processus qui vont contribuer à améliorer la performance de votre organisation. <br/> <br/>
Le Process Mining est un accélérateur de la transformation numérique. </Fragment>}
                img="process_mining_icon.jpeg" />
                <CardType3
                title='L’Automatisation intelligente des Processus (IPA, RPA)'
                relativeUrl="RPA"
                container={<Fragment>L’Automatisation des Processus combine plusieurs technologies, comme : 
                <br/>
                <br/>
                <ul  style={{paddingLeft: 20, marginTop:0, marginBottom:5}}>
                    <li>La Robotic Process Automation (RPA)</li>
                    <li>L’Intelligence Artificielle (IA)</li>
                    <li>La Machine Learning (ML)</li>
                </ul>
                <br/>
                L’Automatisation des Processus permet de moderniser un Système d’Information.
                <br/>
                <br/>
                <br/>
                </Fragment>}
                img="rpa_icon.jpeg" />
                
            </div>




           {Footer()}
            </div>
            
    );
}