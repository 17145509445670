import "../assets/css/cardtype4.css"
export default function CardType4(props) {
    return (
      <div className="card">
          <img src={require("../assets/images/"+props.img)} className="image-card"/>
          <div className="container-card">
            <div className="number-card">{props.number}</div>
            <div className="title-card">{props.title}</div>
          </div>
          
          <div className="text-card">{props.container}</div>
      </div>
    );
  }

  