import "../assets/css/cardtype3.css"
import { Link } from 'react-router-dom';

export default function CardType3(props) {
    return (
      <div className="card3">
          <img src={require("../assets/images/"+props.img)} className="image-card3"/>
          <div className="container-card3">
            <div className="point-card3"></div>
            <div className="title-card3">{props.title}</div>
          </div>
          
          <div className="text-card3">{props.container}</div>

          <Link to={"/"+props.relativeUrl} style={{textDecoration:"none"}}><div className="button-voir-plus">Voir plus</div></Link>
      </div>
    );
  }

  