import Bar from '../Component/Bar'
import CardType1 from '../Component/CardType1';
import '../assets/css/home.css'
import Footer from '../Component/Footer';

import React, { useState, useEffect } from 'react';

export default function Home() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div style={{backgroundColor:'#f9f9f9'}}>
      <Bar />

      <div>
        <img className="image" src={require("../assets/images/accueil_header.jpeg")}/>
      </div>


    <div  style={{display:"flex", justifyContent:"center",alignContent: "center", width:"100%", marginTop: 25}}>
    <img src={require("../assets/images/process_mining_flow2.jpeg")} style={{
      width:900, maxWidth:"100%", padding: 5, backgroundColor:"#ffffff", borderRadius:10, boxShadow: "2px 2px 20px 0px rgb(50 50 50 / 21%)"
    }}/>
      {/*
      <Carousel style={{maxWidth:1100, paddingLeft:60, paddingRight:60}}
      defaultControlsConfig={{
        nextButtonText: ">", prevButtonText: "<"}}> 
          <iframe style={{maxWidth:1100, width:"100%"}}title="Report Section"  height="804" src="https://app.powerbi.com/view?r=eyJrIjoiNTE4OTRlZWQtZWVkYy00YzFiLTk5NTctMmMyNmRiOTNhMTQ1IiwidCI6ImRjYzhjZjEwLTM2NGYtNGJhYi1iZTA5LWQ3OTdlYzBlOWE0MyJ9" frameborder="0" allowFullScreen="true"></iframe>

      
    </Carousel>
      */}
    </div>



      <div className='performace-title'>
        <div className='subtitle-blue'>Une solution pour</div>
        <div className='title'>Améliorer la performance de votre entreprise</div>
        <div className='container-bar'>
          <div className='middle-bar'></div>
        </div>
        
        <div className='subtitle-black'>ODAFOX vous accompagne dans votre quête d’amélioration.</div>

        <div className='container-card1'>
          <CardType1 number='01'
          title='Maîtriser vos processus opérationnels'
          container='La maîtrise de vos processus opérationnels est un préalable pour améliorer la performance de votre organisation et pour réussir votre transformation digitale.'
          img="processus_operationnel.jpeg" />

          <CardType1 number='02'
            title='Soutenir la productivité de vos collaborateurs'
            container='Des applications et des outils performants sont nécessaires pour soutenir et améliorer la productivité de vos collaborateurs.'
            img="productivity.jpeg" />

          {/*<CardType1 number='03'
            title='Exploiter vos données de façon stratégique'
            container='L’analyse de vos transactions permet de déceler des non-conformités; Définir une stratégie d’analyse permet de renforcer votre système de contrôle interne.'
            img="exploitation_donnee.jpeg" />
*/}
</div>
      </div>
  






      <div className='expertise-title' style={{marginBottom:100}}>
        <div className='subtitle-blue'>Process & Data au service de la performance</div>
        <div className='title'>L’Expertise d’ODAFOX</div>
        <div className='container-bar'>
          <div className='middle-bar'></div>
        </div>
        
        {/*<div className='subtitle-black'>ODAFOX est organisé en quatre centres d’expertise : Centre d’analyse et d’automatisation des processus,
         et Centre de management de la sécurité de l’information.</div>*/}
        
      </div>
      {
        /*
        <div className='container-card2'>
        <CardType2 title="Centre d'Analyse des Processus et des Données"
          container="Process Mining, RPA, notre expertise pour une performance optimale de vos processus."
          img="ic:outline-bubble-chart"/>

        <CardType2 title="Centre d’Excellence SAP"
          container="Expertise, Méthodologie et Expérience, l’assurance d’un ROI de votre solution SAP."
          img="ri:bar-chart-grouped-fill"/>

        <CardType2 title="Centre d’Excellence Microsoft"
          container="Notre engagement à vous aider à déployer Microsoft 365, et à l’utiliser de façon optimale."
          img="fa:windows"/>

        <CardType2 title="Centre de Management de la Sécurité de l’Information"
          container="Notre expérience et notre pragmatisme pour accélérer la mise en œuvre de votre SMSI."
          img="ri:file-shield-2-line"/>

      </div>
        */
      }
  {Footer()}      

      {/*<div className='footer'>
        <div className='container-footer'>
          

          <div className='contact-info'>
            <div className='footer-title'>Contact Information</div>
            <div className='footer-subtitle'>301 The Greenhouse, Custard Factory, London, E2 8DY.</div>
            <div className='footer-subtitle'>Email: example@mail.com</div>
            <div className='footer-subtitle'>Phone: +00 112323980</div>
          </div>

          <div className='compagny'>
            <div className='footer-title'>Services</div>
            <div className='footer-subtitle'>Le Process Mining</div>
            <div className='footer-subtitle'>La RPA</div>
            <div className='footer-subtitle'>L'Analyse de données et la detection de fraude</div>
            
          </div>

        </div>
    </div>*/}

    </div>
  );
}