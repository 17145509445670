import Bar from "../Component/Bar";
import Footer from "../Component/Footer";
import "../assets/css/mentions.css"
import { useEffect } from "react";

export default function Mentions() {

    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);


    return (
        <div style={{backgroundColor:'#f9f9f9'}}>
            <Bar />
            <img src={require("../assets/images/mentions_header.png")} style={{maxWidth:"100%", width: "200em"}} />

            <div className='title'>Condition générale d'utilisation</div>
            <div className='container-bar'>
                <div className='middle-bar'></div>
            </div>

            <div className="article">
                <h2>Article 1 : Les mentions légales</h2>
            </div>

            <div className='container-bar'>
                <div className='middle-bar'></div>
            </div>
            <br />


            <p>L'édition et la direction de la publication du site www.odafox.com est assurée par Salick Sall, domicilié au 203 Rue Michel Carré, 95870 Bezons.<br />


                Adresse e-mail admin@odafox.com.<br />

                L’hébergeur du site www. odafox.com est la société HOSTINGER, dont le siège social est situé à Kaunas, en Lituanie.<br />
                <br />
                <b>Editeur du site</b>
                <br />
                Dénomination sociale : Operational Data-Analytics And Automation For Operations Excellence - Odafox (ODAFOX)<br />
                Société A Responsabilité Limité<br />
                RCS PONTOISE : 910 985 290<br />
                Siège social : 20 rue Lavoisier 95300 PONTOISE<br />
                <br />
                <b>Coordonnées</b>
                <br />
                Téléphone : 09 52 03 85 40
                <br />
                <br />
                <b>Directeur de la publication</b>
                <br />
                Mr. Salick SALL
                E-mail : admin@odafox.com
                <br />
                <br />
                <b>Hébergeur du site</b><br />

                Ce site est hébergé par HOSTINGER<br />
                Siège social : Hostinger, UAB Jonavos g. 60C, 44192 Kaunas, Lituanie<br />
                Téléphone : 08 92 97 70 93<br />
                Site web : https://www.hostinger.fr/<br />
                Email : gdpr@hostinger.com<br />

                <br />

                <b>Loi informatique et liberté</b><br />

                Conformément à la loi informatique et liberté du 6 janvier 1978, vous disposez des droits d'opposition, d'accès et de rectification des données vous concernant en application de la loi n° 78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et aux libertés.<br />
                Ces données peuvent être communiquées à des tiers et vous pouvez être amené à recevoir des offres commerciales ou non. Si vous ne le souhaitez pas, il vous suffit d'écrire à l'éditeur du site en indiquant vos noms, prénoms, adresse, adresses e-mail afin qu'il puisse faire le nécessaire dans les meilleurs délais.<br />
                <br />

                <b>Droit d'auteur</b><br />


                Les contenus de ce site sont la propriété de la société ODAFOX, selon la loi n° 92-597 du 1/7/1992 relative au code de la propriété intellectuelle. Toute publication, utilisation ou diffusion, même partielle, du contenu du domaine www.odafox.com doit être autorisée, préalablement et par écrit, par la société ODAFOX.<br />
                <br />
                <b>Crédits Photos</b>
                <br />
                © Adobe Stocks
                <br/>
            </p>

            <div className="article">
                <h2>ARTICLE 2 : Accès au site</h2>
            </div>
            <div className='container-bar'>
                <div className='middle-bar'></div>
            </div>
            <p>
                Le site www.odafox.com permet à l’Utilisateur un accès gratuit aux services suivants :<br/>

                Le site internet propose les services suivants :

                Consulting

                Le site est accessible gratuitement en tout lieu à tout Utilisateur ayant un accès à Internet. Tous les frais supportés par l’Utilisateur pour accéder au service (matériel informatique, logiciels, connexion Internet, etc.) sont à sa charge.
                <br />
            </p>

            <div className="article"> 
                <h2>ARTICLE 3 : Collecte des données</h2>
            </div>
            <div className='container-bar'>
                <div className='middle-bar'></div>
            </div>
            <p>
                Le site est exempté de déclaration à la Commission Nationale Informatique et Libertés (CNIL) dans la mesure où il ne collecte aucune donnée concernant les Utilisateurs.
                <br />
            </p>

            <div className="article">
            <h2>ARTICLE 4 : Propriété intellectuelle</h2>
            </div>
            <div className='container-bar'>
                <div className='middle-bar'></div>
            </div>
            <p>
                Les marques, logos, signes ainsi que tous les contenus du site (textes, images, son) font l’objet d’une protection par le Code de la propriété intellectuelle et plus particulièrement par le droit d’auteur.<br/>

                L’Utilisateur doit solliciter l’autorisation préalable du site pour toute reproduction, publication, copie des différents contenus. Il s’engage à une utilisation des contenus du site dans un cadre strictement privé, toute utilisation à des fins commerciales et publicitaires est strictement interdite.
                <br/>
                Toute représentation totale ou partielle de ce site par quelque procédé que ce soit, sans l'autorisation expresse de l'exploitant du site Internet constituerait une contrefaçon sanctionnée par l'article L 335-2 et suivants du Code de la propriété intellectuelle.
                <br/>
                Il est rappelé conformément à l'article L122-5 du Code de propriété intellectuelle que l'Utilisateur qui reproduit, copie ou publie le contenu protégé doit citer l'auteur et sa source.
                <br />
            </p>

            <div className="article">
                <h2>ARTICLE 5 : Responsabilité</h2>
            </div>
            <div className='container-bar'>
                <div className='middle-bar'></div>
            </div>
            <p>
                Les sources des informations diffusées sur le site www.odafox.com sont réputées fiables mais le site ne garantit pas qu'il soit exempt de défauts, d'erreurs ou d'omissions.<br/>

                Les informations communiquées sont présentées à titre indicatif et général sans valeur contractuelle. Malgré des mises à jour régulières, le site www.odafox.com ne peut être tenu responsable de la modification des dispositions administratives et juridiques survenant après la publication. De même, le site ne peut être tenue responsable de l'utilisation et de l'interprétation de l'information contenue dans ce site.<br/>

                Le site www.odafox.com ne peut être tenu pour responsable d'éventuels virus qui pourraient infecter l'ordinateur ou tout matériel informatique de l'Internaute, suite à une utilisation, à l'accès, ou au téléchargement provenant de ce site.

                La responsabilité du site ne peut être engagée en cas de force majeure ou du fait imprévisible et insurmontable d’un tiers.<br/>
                <br />
            </p>

            <div className="article">
            <h2>ARTICLE 6 : Liens hypertextes</h2>
            </div>
            <div className='container-bar'>
                <div className='middle-bar'></div>
            </div>
            <p>
                Des liens hypertextes peuvent être présents sur le site. L'Utilisateur est informé qu'en cliquant sur ces liens, il sortira du site www.odafox.com. Ce dernier n'a pas de contrôle sur les pages web sur lesquelles aboutissent ces liens et ne saurait, en aucun cas, être responsable de leur contenu.
                <br />
            </p>

            <div className="article">
            <h2>ARTICLE 7 : Cookies</h2>
            </div>
            <div className='container-bar'>
                <div className='middle-bar'></div>
            </div>
            <p>
                L'Utilisateur est informé que lors de ses visites sur le site, un cookie peut s'installer automatiquement sur son logiciel de navigation.
                <br/>
                Les cookies sont de petits fichiers stockés temporairement sur le disque dur de l'ordinateur de l'Utilisateur par votre navigateur et qui sont nécessaires à l'utilisation du site www.odafox.com. Les cookies ne contiennent pas d'information personnelle et ne peuvent pas être utilisés pour identifier quelqu'un. Un cookie contient un identifiant unique, généré aléatoirement et donc anonyme. Certains cookies expirent à la fin de la visite de l'Utilisateur, d'autres restent.
                <br/>
                L'information contenue dans les cookies est utilisée pour améliorer le site www.odafox.com.
                <br/>
                En naviguant sur le site, L'Utilisateur les acceptes.
                <br/>
                L'Utilisateur doit toutefois donner son consentement quant à l'utilisation de certains cookies.
                <br/>
                A défaut d'acceptation, l'Utilisateur est informé que certaines fonctionnalités ou pages risquent de lui être refusées.
                <br/>
                L'Utilisateur pourra désactiver ces cookies par l'intermédiaire des paramètres figurant au sein de son logiciel de navigation.
                <br />
            </p>

            <div className="article">
            <h2>ARTICLE 8 : Droit applicable et juridiction compétente</h2>
            </div>
            <div className='container-bar'>
                <div className='middle-bar'></div>
            </div>
            <p style={{marginBottom:50}}>
                La législation française s’applique au présent contrat. En cas d’absence de résolution amiable d’un litige né entre les parties, les tribunaux français seront seuls compétents pour en connaître.

                Pour toute question relative à l'application des présentes CGU, vous pouvez joindre l'éditeur aux coordonnées inscrites à l'ARTICLE 1.
            </p>

            <Footer />
        </div>
    )
}